<template>
    <div class="pt-6 positionGeneralDiv">
        <div class="positionDiv">
            <section>
                <div class="row positionGenralImg">
                    <v-img
                        class="imgSet"
                        lazy-src="https://picsum.photos/id/11/10/6"
                        max-height="175"
                        max-width="100%"
                        src="@/assets/login-img.jpg"
                    ></v-img>
                </div>

                <h1>
                    Seja bem vindo<br />
                    <span>à nossa plataforma!</span>
                </h1>

                <div class="favIconDiv"></div>

                <v-form :lazy-validation="true" class="divForm" v-model="valid" ref="form">
                    <label for="login-user">Usuário</label>
                    <v-text-field
                        outlined
                        dense
                        id="login-user"
                        class="custom-input"
                        placeholder="Digite seu usuário"
                        v-model="usuario.email"
                        :rules="[rules.required, rules.email]"
                        :validate-on-blur="true"
                    ></v-text-field>
                    <label for="login-password">Senha</label>
                    <v-text-field
                        id="login-password"
                        placeholder="Digite sua senha"
                        v-model="usuario.senha"
                        min="8"
                        outlined
                        dense
                        class="custom-input"
                        :append-icon="showPwdField ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPwdField ? 'text' : 'password'"
                        :rules="[rules.required]"
                        @click:append="showPwdField = !showPwdField"
                        :validate-on-blur="true"
                    ></v-text-field>
                    <v-layout>
                        <v-btn
                            block
                            @click="submitLogin"
                            :class="{
                                'teal accent-4  white--text': valid,
                                disabled: !valid || signInRequestStatus === RequestStatusEnum.START,
                                secondary: true,
                            }"
                            class="text-capitalize"
                            elevation="0"
                            title="Entrar"
                            :disabled="!valid"
                        >
                            Entrar
                        </v-btn>
                    </v-layout>

                    <Loader
                        class="text-center mt-3"
                        v-if="signInRequestStatus === RequestStatusEnum.START"
                        mode="overlay"
                    />
                </v-form>
                <v-layout justify-center>
                    <router-link
                        class="styleLink"
                        to="/recupecao-senha"
                        title="Esqueci minha senha?"
                    >
                        <v-icon size="18">mdi-lock</v-icon> Esqueci minha senha?
                    </router-link>
                </v-layout>
            </section>

            <v-layout justify-center class="footer">
                <span> © 2021 Farm Investimentos. Todos os direitos reservados. </span>
            </v-layout>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from '@/helpers/notification';
import { email as emailValidator } from '@/helpers/validators';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';
export default {
    name: 'login',
    components: {
        Loader,
    },
    data: () => ({
        usuario: {
            email: null,
            senha: null,
        },
        rules: {
            required: value => !!value || 'Campo obrigatório',
            email: v => emailValidator(v) || 'E-mail inválido',
        },
        valid: false,
        showPwdField: false,
        RequestStatusEnum,
    }),
    computed: {
        ...mapGetters('auth', {
            signInRequestStatus: 'signInRequestStatus',
        }),
    },
    mounted() {
        this.resetState();
    },
    methods: {
        ...mapActions('auth', {
            setUser: 'login',
            resetState: 'resetState',
            keepConnected: 'keepConnected',
        }),

        submitLogin() {
            this.keepConnected(true);
            this.setUser({ ...this.usuario });
        },
    },
    watch: {
        signInRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Usuário autenticado com sucesso!');
                return RequestStatusEnum.SUCCESS;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Erro ao validar usuário: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import './Login.scss';
</style>
